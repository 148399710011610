import { useEffect, useRef, useState, useCallback } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import Assets from "assets/images";
import OutlineButton from "components/elements/OutlineButton";
import PrimaryButton from "components/elements/PrimaryButton";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill, BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { exerciseWorkoutSchema } from "validations/formsValidations";

import { Images } from "assets";
import ActionSheet from "components/compounds/ActionSheet";
import CustomToastify from "components/compounds/Toast";
import { constants } from "constant";
import { GoKebabHorizontal } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  completeWorkoutExercise,
  playWorkoutExercise,
} from "store/traineeSlice/traineeActions";
import {
  completeExercise,
  playExercise,
} from "store/workoutSlice/workoutActions";
import { useWorkoutSelector } from "store/workoutSlice/workoutReducer";
import { Modal } from "components/elements/Modal";
import ReactPlayer from "react-player";

const advanceTechniqueOptions = [
  { id: 1, label: "Cluster Sets", value: "cluster-sets" },
  { id: 2, label: "Rest-Pause Sets", value: "rest-pause-sets" },
  { id: 3, label: "Drop Sets", value: "drop-sets" },
  { id: 4, label: "Super Sets", value: "super-sets" },
  { id: 5, label: "Giant Sets", value: "giant-sets" },
  { id: 6, label: "Pyramid Sets", value: "pyramid-sets" },
  { id: 7, label: "Negatives", value: "negatives" },
  { id: 8, label: "Forced Reps", value: "forced-Reps" },
  { id: 9, label: "Isometric Holds", value: "isometric-holds" },
  { id: 10, label: "Pre-Exhaustion", value: "pre-exhaustion" },
];

const Switch = ({
  name,
  id,
  checked,
  label,
  register,
  t,
  disabled,
  direction,
}) => {
  return (
    <div className="relative inline-block w-8 mr-2 align-middle select-none text-center">
      {label && (
        <label
          className={`text-xs ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
        >
          {t(label)}
        </label>
      )}
      <input
        name={name}
        id={id}
        type="checkbox"
        className={`toggle-checkbox absolute block w-5 h-5 rounded-full border-4 appearance-none cursor-pointer ${
          checked
            ? "right-0 border-borderActive bg-white"
            : "left-0 border-textLightGrey bg-[#72777F]"
        } transition-transform`}
        checked={checked}
        disabled={disabled}
        {...register}
      />
      <label
        htmlFor={id}
        className={`toggle-label block overflow-hidden h-5 rounded-full bg-borderActive cursor-pointer ${
          direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
        } ${checked ? "bg-borderActive" : "bg-textLightGrey border-textLightGrey"} transition-background-color`}
      />
    </div>
  );
};

const Checkbox = ({ id, checked, label, onChange, disabled, direction }) => {
  return (
    <div className="flex flex-row space-x-2 rtl:space-x-reverse items-center">
      <div className="h-full flex justify-start items-center">
        <input
          disabled={disabled}
          id={id}
          type="checkbox"
          checked={checked}
          className={`h-4 w-4 custom_checkbox ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
          onChange={onChange}
        />
      </div>
      <p
        className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-xs leading-4 text-white`}
      >
        {label}
      </p>
    </div>
  );
};

const Input = ({
  name,
  defaultValue,
  className,
  inputClassName,
  label,
  value,
  displayValue,
  type,
  placeholder,
  onChange,
  register,
  disabled,
  error,
  errorMessage,
  isTrainee,
  t,
}) => {
  const [showActionSheet, setActionSheet] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const toggleActionSheet = () => {
    setActionSheet(!showActionSheet);
    if (showActionSheet) {
      handleInputBlur();
    }
  };

  const handleChange = (e) => {
    if (type === "number") {
      const inputValue = e.target.value.replace(/[^0-9]/g, "");
      onChange(inputValue);
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <div className={className}>
      <div
        className={`bg-textFieldColor rounded-lg shadow-xl flex flex-col p-2 ${!disabled ? "hover:bg-[#404b51]" : "border-2 border-[#404b51]"}   ${
          isInputFocused && !error ? "border-2 border-[#006684]" : ""
        } ${error ? "border-2 border-error" : ""} `}
      >
        <label
          className={`text-xs overflow-hidden ${isInputFocused && !error ? "text-[#65B1F4]" : ""} ${error ? "text-error" : ""}`}
        >
          {label}
        </label>
        <input
          name={name}
          defaultValue={defaultValue}
          type={type}
          disabled={showActionSheet ? true : disabled}
          value={value}
          step="any"
          placeholder={placeholder}
          onChange={handleChange}
          className={`bg-transparent outline-none border-none text-xs p-1 ${inputClassName}`}
          {...register}
          onClick={() => isTrainee && !disabled && toggleActionSheet()}
          readOnly={isTrainee && !disabled}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />

        {isTrainee && (
          <ActionSheet
            open={showActionSheet}
            onClose={toggleActionSheet}
            custom
            customComponent={() => {
              return (
                <div className="h-full w-full flex flex-col items-center space-y-2 py-4">
                  <h1 className="text-xl text-[#FFF] ltr:font-primary-rg rtl:font-primary-he-rg">
                    {t(label)}
                  </h1>
                  <div className="flex items-center space-x-4 rtl:space-x-reverse">
                    <div
                      className="bg-[#4e535a] w-20 h-20 rounded-full text-4xl flex items-center justify-center cursor-pointer text-[#FFF]"
                      onClick={() => onChange(parseFloat(displayValue) + 1)}
                    >
                      +
                    </div>
                    <div className="text-[60px] text-[#FFF] ltr:font-primary-rg rtl:font-primary-he-rg font-normal leading-[58px]">
                      {displayValue}
                    </div>
                    <div
                      className="bg-[#4e535a] w-20 h-20 rounded-full text-4xl font-primary-rg flex items-center justify-center cursor-pointer text-[#FFF]"
                      onClick={() => onChange(parseFloat(displayValue) - 1)}
                    >
                      -
                    </div>
                  </div>
                  <div
                    className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm cursor-pointer"
                    onClick={() => onChange(parseFloat(displayValue) + 0.5)}
                  >
                    {t("ADD")} 0.5+
                  </div>
                  <div
                    className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm cursor-pointer"
                    onClick={() => onChange(parseFloat(displayValue) + 10)}
                  >
                    {t("ADD")} 10+
                  </div>
                </div>
              );
            }}
          />
        )}
      </div>
      {error && (
        <p className="text-[9px] text-error">{errorMessage || "Error"}</p>
      )}
    </div>
  );
};

const Dropdown = ({
  label,
  options,
  value,
  onChange,
  t,
  disabled,
  direction,
}) => {
  const [isOpen, setOpen] = useState(false);
  const divRef = useRef();
  const toggleOpener = () => setOpen(disabled ? false : !isOpen);
  useOnClickOutside(divRef, () => setOpen(false));

  return (
    <div
      className="flex items-center space-x-4 rtl:space-x-reverse relative"
      ref={divRef}
      onClick={toggleOpener}
    >
      {label && (
        <label
          className={`text-xs ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
        >
          {label}
        </label>
      )}
      <div
        className={`flex items-center space-x-1 rtl:space-x-reverse cursor-pointer opacity-100 ${disabled && "opacity-50"}`}
      >
        <h6
          className={`text-xs ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} ${value && "font-bold"}`}
        >
          {value || t("SELECT_OPTION")}
        </h6>
        <BsChevronDown fontSize={"0.9rem"} />
      </div>
      {isOpen && (
        <div className="absolute top-6 left-10 rounded-lg shadow-2xl bg-borderColor z-50">
          {options?.map((item) => (
            <div
              className={`w-64 px-6 py-2 bg-borderColor rounded-lg hover:bg-statusBg cursor-pointer text-xs ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              }`}
              key={item.id}
              onClick={() => onChange(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Exercise = ({
  name,
  indexKey,
  workoutId,
  workoutName,
  exerciseId,
  exerciseName,
  workoutExerciseId,
  subtitle,
  traineeId,
  sets,
  machineName,
  onSwapClick,
  onHistoryClick,
  onCommentsClick,
  onRemoveExercise,
  isIntensityTracking,
  isBoostWeight,
  isWorkoutHasOneExercise,
  status,
  isFirstExercisePlayed,
  isWorkoutSubmitting,
  advanceTechniqueValue,
  mobility_technique,
  muscle_technique,
  control_technique,
  handleDragStart,
  handleDragOver,
  handleDrop,
  openNextExercise,
  openNextIndex,
  resetNextHandler,
  isTrainee,
  refresh,
  data,
  setData,
  onClickExerciseInfo,
  setBestRecordId,
  bestRecordId,
  hasLastComment,
  url,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeWorkouts } = useWorkoutSelector();
  const { loader } = useSelector((state) => state?.exercise);
  const [isOpen, setOpen] = useState(false);
  const [removedSetIds, setRemovedIds] = useState([]);
  const [addedIds, setAddedIds] = useState([]);
  const [addAdvanceTechnique, setAdvanceTechnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addIntensityTracking, setIntensityTracking] = useState(false);
  const { bands } = useSelector((state) => state.gymAdmin);
  const toggleOpener = () => setOpen(!isOpen);
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const closeModal = () => {
    setIsOpenVideo(!isOpenVideo);
  };
  const [isExerciseMenuOpen, setExerciseMenuOpen] = useState(false);
  const { direction } = useSelector((state) => state.language);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleExerciseMenu = () => setExerciseMenuOpen(!isExerciseMenuOpen);
  const toggleAdvanceTechnique = () => {
    setAdvanceTechnique(!addAdvanceTechnique);
    isTrainee && toggleExerciseMenu();
  };
  const toggleIntensityTracking = () => {
    setIntensityTracking(!addIntensityTracking);
    isTrainee && toggleExerciseMenu();
  };
  const toggleMenuOpen = () => setMenuOpen(!isMenuOpen);
  const isRTL = direction === "rtl";

  const isExerciseCompleted = status === "completed";
  const isExercisePartial = status === "partial";
  const divRef = useRef();

  useOnClickOutside(divRef, () => {
    setMenuOpen(false);
  });

  useEffect(() => {
    if (openNextIndex && openNextIndex === indexKey) {
      toggleOpener();
      resetNextHandler();
    }
    // eslint-disable-next-line
  }, [openNextIndex]);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(exerciseWorkoutSchema),
    defaultValues: {
      sets: [],
    },
  });

  const fields = watch("sets");
  const advanceTechnique = watch("advanceTechnique");
  const mobility_technique_value = watch("mobility_technique");
  const muscle_technique_value = watch("muscle_technique");
  const control_technique_value = watch("control_technique");

  useEffect(() => {
    const newValues = sets.map((item) => {
      return {
        id: item.setId,
        resistance_value: item.resistance_value || 0,
        tracking_value: item.tracking_value || 0,
        resistancename: item?.resistancename,
        trackingname: item?.trackingname,
        intensity_level: item.intensity_level < 0 ? 1 : item.intensity_level,
        isBoost: item.isBoost,
        setIndex: item.setIndex,
      };
    });
    setValue("sets", newValues);
    setValue("advanceTechnique", null);
    setValue("intensityTracking", false);
    setValue("mobility_technique", mobility_technique);
    setValue("muscle_technique", muscle_technique);
    setValue("control_technique", control_technique);
    // eslint-disable-next-line
  }, [sets]);

  useEffect(() => {
    setIntensityTracking(isIntensityTracking);
    if (advanceTechniqueValue) {
      setAdvanceTechnique(true);
      setValue("advanceTechnique", advanceTechniqueValue);
    }
    // eslint-disable-next-line
  }, [sets]);

  const onExerciseCompleteClick = () => {
    //Completing Exercise before done
    if (isTrainee) {
      const updatedData = { ...data };

      // Find the exercise to update its workoutExerciseStatus
      const exerciseToUpdate = updatedData?.workoutData?.exercises?.find(
        (exercise) => exercise.workoutExerciseId === workoutExerciseId
      );

      if (exerciseToUpdate) {
        exerciseToUpdate.workoutExerciseStatus = "completed";
      }

      // Update the state with the modified data
      setData(updatedData);
    }

    handleSubmit(async (formData) => {
      try {
        const firstSet = sets[0];

        let newItems = formData.sets.filter(
          (item) =>
            addedIds.includes(item.id) && !removedSetIds.includes(item.id)
        );

        let updatedSets = formData.sets.filter(
          (item) => !removedSetIds.includes(item.id)
        );

        updatedSets = updatedSets.map((item) => ({
          resistancename: firstSet?.resistancename,
          resistancetype: firstSet.resistancetype,
          trackingname: firstSet.trackingname,
          trackingunit: firstSet.trackingunit,
          intensity_level: item.intensity_level,
          resistance_value: item.resistance_value,
          tracking_value: item.tracking_value,
          isBoost: item.isBoost,
          id: item.id,
          setIndex: item.setIndex,
        }));
        newItems = newItems.map((item) => ({
          resistancename: firstSet.resistancename,
          resistancetype: firstSet.resistancetype,
          trackingname: firstSet.trackingname,
          trackingunit: firstSet.trackingunit,
          intensity_level: item.intensity_level,
          resistance_value: item.resistance_value,
          tracking_value: item.tracking_value,
          isBoost: item.isBoost,
          id: item.id,
        }));
        const payload = {
          workoutId,
          workoutExerciseId,
          exerciseId,
          removedSetIds: removedSetIds.filter(
            (item) => typeof item !== "string"
          ),
          newSets: newItems,
          advancedTechniques: advanceTechnique,
          mobility_technique: mobility_technique_value,
          muscle_technique: muscle_technique_value,
          control_technique: control_technique_value,
          updatedSets: updatedSets.filter(
            (item) => typeof item.id !== "string"
          ),
          isIntensityTracking: addIntensityTracking,
        };

        //
        setLoading(false);
        setRemovedIds([]);
        // toggleOpener()
        // openNextExercise(indexKey)
        const action = () => {
          setLoading(false);
          // setRemovedIds([])
          toggleOpener();
          // openNextExercise(indexKey)
        };
        if (isTrainee) {
          await completeWorkoutExercise(payload, setBestRecordId, bestRecordId);
          refresh && refresh(true);
          action();
        } else {
          dispatch(
            completeExercise(
              payload,
              workoutId,
              action,
              t,
              direction,
              setBestRecordId,
              bestRecordId
            )
          );
        }
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    })();
  };

  const onExerciseStartClick = async () => {
    setLoading(true);
    try {
      const payload = {
        workoutId: isFirstExercisePlayed ? workoutId : null,
        workoutExerciseId,
      };
      const action = () => {
        setLoading(false);
      };
      if (isTrainee) {
        await playWorkoutExercise(payload);
        refresh && refresh(true);
        action();
        CustomToastify(
          t("EXERCISE_PLAYED_SUCCESSFULLLY"),
          "",
          "",
          "bg-toastSuccessbg",
          direction,
          Images.toastSucess,
          true
        );
      } else {
        dispatch(playExercise(payload, workoutId, action, t, direction));
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  const addNewSet = () => {
    const latestData = getValues("sets");
    const getMaxId = latestData.reduce((max, item) => (item.id > max ? item.id : max), latestData[0].id);
    const getMaxSetIndex = latestData.reduce((max, item) => (item.setIndex > max ? item.setIndex : max), latestData[0].setIndex);

    const lastSetLatestValues = latestData[latestData?.length - 1];
    const lastSet = sets[sets?.length - 1];
    if (removedSetIds.length > 0) {
      const removedId = [...removedSetIds];
      setRemovedIds(removedId.slice(0, -1));
    } else {
      const newValues = [
        ...fields,
        {
          // id: `new-${new Date().getTime()}`,
          id: getMaxId+1000,
          resistance_value: lastSetLatestValues?.resistance_value,
          tracking_value: lastSetLatestValues?.tracking_value,
          intensity_level: lastSetLatestValues?.intensity_level,
          isBoost: false,
          resistancename: (lastSet && lastSet?.resistancename) || " ",
          resistancetype: (lastSet && lastSet?.resistancetype) || " ",
          trackingname: (lastSet && lastSet?.trackingname) || " ",
          trackingunit: (lastSet && lastSet?.trackingunit) || " ",
          setIndex: getMaxSetIndex + 1000
        },
      ];
      setAddedIds([...addedIds, getMaxId+1000])
      setValue("sets", newValues);
    }
  };

  const onRemoveIndex = (setId) => {
    setRemovedIds([...removedSetIds, setId]);
  };

  const gettingSelectedBands = (data, setValue, index) => {
    const matchingBand = bands.find(
      (band) => band.bandLevel === data?.resistance_value
    );
    let resist_Val = 0;
    if (matchingBand) {
      resist_Val = handleSelectBandColor(matchingBand?.machineName);
      setValue(`sets[${index}].resistance_value`, resist_Val);

      return matchingBand?.machineName;
    }
    resist_Val = handleSelectBandColor(bands[0]?.machineName);

    setValue(`sets[${index}].resistance_value`, resist_Val);

    return bands[0]?.machineName;
  };

  const handleSelectBandColor = (selectedColor, index) => {
    const selectedBand = bands.find(
      (band) => band.machineName === selectedColor
    );
    const resistanceValue = selectedBand ? selectedBand.bandLevel : null;
    return resistanceValue;
  };
  const getAllPartail = (workoutId) => {
    let isPartial = false;
    if (data) {
      data.workoutData?.exercises?.forEach((exercise) => {
        if (
          exercise.workoutExerciseStatus === "partial" ||
          exercise.workoutExerciseStatus === "completed"
        ) {
          isPartial = true;
        }
      });
    } else {
      activeWorkouts.forEach((item) => {
        const workout = item.workoutData;

        if (workout.id === workoutId) {
          workout.exercises.forEach((exercise) => {
            if (
              exercise.workoutExerciseStatus === "partial" ||
              exercise.workoutExerciseStatus === "completed"
            ) {
              isPartial = true;
            }
          });
        }
      });
    }

    return isPartial;
  };

  return (
    <div
      className={`w-full bg-playCard rounded-lg p-4 ${isExerciseCompleted && "border border-[#9FF976]"}  ${
        isWorkoutSubmitting && !isExerciseCompleted && "border border-error"
      }`}
      draggable
      onDragStart={(event) => handleDragStart(event, workoutExerciseId)}
      onDragOver={handleDragOver}
      onDrop={(event) => handleDrop(event, workoutExerciseId)}
    >
      <Modal open={isOpenVideo} handleClose={closeModal}>
        {url && <ReactPlayer url={url} />}
      </Modal>
      {isTrainee ? (
        <div className="flex justify-between items-center cursor-pointer">
          <div
            className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
            onClick={toggleOpener}
          >
            <img
              src={
                isRTL
                  ? Assets.WorkoutSidebarLeftArrow
                  : Assets.WorkoutSidebarRightArrow
              }
              alt="profile"
              className={`w-2 h-3 rounded-full bg-cover text-center shadow-xl ${isOpen ? (isRTL ? "-rotate-90" : "rotate-90") : ""}`}
            />
            <h6
              className={`text-sm text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {name}
            </h6>
          </div>
          {isExerciseCompleted ? (
            <BsCheckCircleFill className="text-[#9FF976]" size={24} />
          ) : (
            <>
              {!isOpen && (
                <h6
                  className={`text-xs whitespace-nowrap text-[#8C9198] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  {subtitle}
                </h6>
              )}
              {isTrainee && isOpen && (
                <GoKebabHorizontal
                  fontSize="1.7rem"
                  className="text-white cursor-pointer"
                  onClick={toggleExerciseMenu}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex justify-between items-center cursor-pointer relative">
          <div
            className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
            onClick={toggleOpener}
          >
            <img
              src={
                isRTL
                  ? Assets.WorkoutSidebarLeftArrow
                  : Assets.WorkoutSidebarRightArrow
              }
              alt="profile"
              className={`w-2 h-3 rounded-full bg-cover text-center shadow-xl ${isOpen ? (isRTL ? "-rotate-90" : "rotate-90") : ""}`}
            />
            <h6
              className={`text-sm text-white font-medium ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {name}
            </h6>
          </div>
          {isExerciseCompleted ? (
            <BsCheckCircleFill className="text-[#9FF976]" size={24} />
          ) : (
            <>
              {!isOpen && (
                <h6
                  className={`text-xs whitespace-nowrap text-[#8C9198] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  {subtitle}
                </h6>
              )}
              {isOpen && (
                <GoKebabHorizontal
                  fontSize="1.7rem"
                  className="text-white cursor-pointer"
                  onClick={toggleMenuOpen}
                />
              )}
              {isMenuOpen && (
                <div
                  className="absolute top-7 ltr:right-0 rtl:left-0 rounded-lg shadow-2xl bg-borderColor z-50 overflow-hidden"
                  ref={divRef}
                >
                  <div
                    className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-2 px-4 opacity-100 hover:bg-checkBoxGray `}
                    onClick={() => {
                      let isAllPartial = getAllPartail(workoutId);

                      onSwapClick(
                        workoutExerciseId,
                        workoutId,
                        exerciseId,
                        traineeId,
                        exerciseName,
                        isAllPartial
                      );
                      toggleMenuOpen();
                    }}
                  >
                    <img
                      src={Images.Pen}
                      alt=""
                      height={20}
                      width={20}
                      className={`items-center cursor-pointer`}
                    />
                    <h6
                      className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {t("SWAP_EXERCISE")}
                    </h6>
                  </div>
                  <div
                    className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-2 px-4 opacity-100 hover:bg-checkBoxGray 
                    `}
                    onClick={() => {
                      if (!isWorkoutHasOneExercise) {
                        onRemoveExercise(workoutExerciseId, workoutId);
                        toggleMenuOpen();
                      }
                    }}
                  >
                    <img
                      src={Images.Delete}
                      alt=""
                      height={20}
                      width={20}
                      className={`items-center cursor-pointer`}
                    />
                    <h6
                      className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {t("REMOVE_EXERCISE")}
                    </h6>
                  </div>
                  <div
                    className="cursor-pointer border-t-[1px] border-extralightGray py-2 px-4 opacity-100 hover:bg-checkBoxGray"
                    onClick={() => {
                      toggleAdvanceTechnique();
                      toggleMenuOpen();
                    }}
                  >
                    <h6
                      className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {addAdvanceTechnique
                        ? t("HID_ADVANCE_TECHNIQUE")
                        : t("ADD_ADVANCE_TECHNIQUE")}
                    </h6>
                  </div>
                  <div
                    className="cursor-pointer py-2 px-4 opacity-100 hover:bg-checkBoxGray"
                    onClick={() => {
                      toggleIntensityTracking();
                      toggleMenuOpen();
                    }}
                  >
                    <h6
                      className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {addIntensityTracking
                        ? t("REMOVE_INTENSITY")
                        : t("ADD_INTENSITY_TRACKING")}
                    </h6>
                  </div>
                  <div className="flex flex-col px-4 border-t-[1px] border-extralightGray py-2">
                    <div
                      className={`flex text-sm text-white font-normal ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {t("TECHNIQUE")}
                    </div>
                    <div className="flex flex-col py-2 gap-2 ">
                      <Checkbox
                        disabled={!isExercisePartial}
                        label={t("MOVEMENT_RANGE")}
                        checked={mobility_technique_value}
                        onChange={(e) =>
                          setValue(`mobility_technique`, e.target.checked)
                        }
                        direction={direction}
                      />
                      <Checkbox
                        disabled={!isExercisePartial}
                        label={t("MUSCLE_FEELING")}
                        checked={muscle_technique_value}
                        onChange={(e) =>
                          setValue(`muscle_technique`, e.target.checked)
                        }
                        direction={direction}
                      />
                      <Checkbox
                        disabled={!isExercisePartial}
                        label={t("CONTROLLED_MOVEMENT")}
                        checked={control_technique_value}
                        onChange={(e) =>
                          setValue(`control_technique`, e.target.checked)
                        }
                        direction={direction}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        // </RightClickMenu>
      )}

      {isOpen && (
        <div className="pt-4 space-y-4">
          {addAdvanceTechnique && (
            <Dropdown
              disabled={!isExercisePartial}
              label={t("ADVANCE_TECHNIQUE")}
              value={advanceTechnique}
              options={advanceTechniqueOptions}
              onChange={(item) => setValue(`advanceTechnique`, item.value)}
              t={t}
              direction={direction}
            />
          )}
          {fields?.sort((a,b) => (a.setIndex || 0) - (b.setIndex || 0)).map((item, index) => {
            return (
              <div
                key={index}
                className={`max-w-full ${index === 0 ? "pt-4" : "pt-2"} flex justify-between items-center space-x-1 rtl:space-x-reverse opacity-100 ${
                  removedSetIds.includes(item.id) && "opacity-30"
                }
                ${!isExercisePartial && "opacity-40"}
                `}
              >
                <h1
                  className={`text-sm whitespace-nowrap w-2/12 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >{`${t("SET")} ${index + 1}`}</h1>
                <div
                  className={`flex space-x-2 rtl:space-x-reverse w-6/12 ${!isBoostWeight && "w-8/12"}`}
                >
                  {item?.resistancename === constants.RUBBER_BAND ? (
                    <div
                      className={`flex flex-col p-2 bg-textFieldColor rounded-lg w-1/2 ${addIntensityTracking && "w-1/3"}`}
                    >
                      <p
                        className={`text-xs overflow-hidden text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                      >
                        {item?.resistancename}
                      </p>
                      <p
                        className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                      >
                        <select
                          disabled={
                            removedSetIds.includes(item.id) ||
                            !isExercisePartial
                          }
                          className={`bg-textFieldColor outline-none text-white text-xs ${
                            direction === "ltr"
                              ? "font-primary-rg"
                              : "font-primary-he-rg"
                          }`}
                          onChange={(e) => {
                            const resist_Val = handleSelectBandColor(
                              e.target.value
                            );
                            setValue(
                              `sets[${index}].resistance_value`,
                              resist_Val
                            );
                          }}
                          defaultValue={gettingSelectedBands(
                            item,
                            setValue,
                            index
                          )}
                          register={control.register(
                            `sets.${index}.resistance_value`
                          )}
                        >
                          {bands.map((band) => (
                            <option
                              key={band.id}
                              value={band.machineName}
                              className={`text-white text-sm ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {band.machineName}
                            </option>
                          ))}
                        </select>
                      </p>
                    </div>
                  ) : (
                    <Input
                      type="number"
                      label={t(item?.resistancename)}
                      className={`w-1/2 ${addIntensityTracking && "w-1/3"} ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                      onChange={(newValue) => {
                        setValue(`sets[${index}].resistance_value`, newValue, {
                          shouldValidate: true,
                        });
                      }}
                      disabled={
                        removedSetIds.includes(item.id) || !isExercisePartial
                      }
                      register={control.register(
                        `sets.${index}.resistance_value`
                      )}
                      displayValue={fields[index].resistance_value}
                      error={errors?.sets?.[index]?.resistance_value ?? null}
                      errorMessage={
                        errors?.sets?.[index]?.resistance_value?.message ?? null
                      }
                      isTrainee={isTrainee}
                      t={t}
                    />
                  )}
                  <Input
                    type="number"
                    label={t(item?.trackingname)}
                    className={`w-1/2 ${addIntensityTracking && "w-1/3"} ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    disabled={
                      removedSetIds.includes(item.id) || !isExercisePartial
                    }
                    onChange={(newValue) => {
                      setValue(`sets[${index}].tracking_value`, newValue, {
                        shouldValidate: true,
                      });
                    }}
                    displayValue={fields[index].tracking_value}
                    register={control.register(`sets.${index}.tracking_value`)}
                    error={errors?.sets?.[index]?.tracking_value ?? null}
                    errorMessage={
                      errors?.sets?.[index]?.tracking_value?.message ?? null
                    }
                    isTrainee={isTrainee}
                    t={t}
                  />
                  {addIntensityTracking && (
                    <Input
                      type="number"
                      label={"RPE"}
                      className={`w-1/3 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                      disabled={
                        removedSetIds.includes(item.id) || !isExercisePartial
                      }
                      displayValue={fields[index].intensity_level}
                      onChange={(newValue) => {
                        setValue(`sets[${index}].intensity_level`, newValue, {
                          shouldValidate: true,
                        });
                      }}
                      register={control.register(
                        `sets.${index}.intensity_level`
                      )}
                      error={errors?.sets?.[index]?.intensity_level ?? null}
                      errorMessage={
                        errors?.sets?.[index]?.intensity_level?.message ?? null
                      }
                      isTrainee={isTrainee}
                      t={t}
                    />
                  )}
                </div>
                {isBoostWeight && (
                  <div
                    className={`h-full flex justify-center items-center w-2/12 pl-5`}
                  >
                    <Switch
                      disabled={
                        removedSetIds.includes(item.id) || !isExercisePartial
                      }
                      checked={item.isBoost}
                      label={t("BOOST")}
                      id={item.id}
                      onChange={() =>
                        control.setValue(
                          `sets[${index}].isBoost`,
                          !item.isBoost
                        )
                      }
                      register={control.register(`sets.${index}.isBoost`)}
                      t={t}
                      direction={direction}
                    />
                  </div>
                )}
                <div
                  className={`w-2/12 flex justify-end opacity-100 ${isExercisePartial && "hover:opacity-60"}`}
                >
                  <img
                    src={Assets.Remove}
                    alt="remove-icon"
                    className={`h-6 w-6 cursor-pointer object-cover`}
                    onClick={() =>
                      !removedSetIds.includes(item.id) &&
                      isExercisePartial &&
                      onRemoveIndex(item.id)
                    }
                  />
                </div>
              </div>
            );
          })}
          {machineName && (
            <div>
              <h6
                className={`text-xs ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              >
                {t("INFO")} :{" "}
                <span
                  className={`text-white rounded-lg p-1 bg-borderActive cursor-pointer ${
                    direction === "ltr"
                      ? "font-primary-rg"
                      : "font-primary-he-rg"
                  }`}
                >{`#${machineName}`}</span>
              </h6>
            </div>
          )}
          <div className="flex pb-1 space-x-1 rtl:space-x-reverse">
            <OutlineButton
              text={t("ADD_SET")}
              type="submit"
              className="w-4/6 h-10 rounded-lg shadow-2xl opacity-100 hover:opacity-70"
              bgColor="rounded-lg cursor-pointer border-borderActive border-[1.5px] hover:bg-[#404b51]"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              wid={16}
              ht={16}
              isIcon={true}
              icon={Assets.Plus}
              onClick={addNewSet}
              disabled={!isExercisePartial}
            />

            {/*-------- info -------- */}
            <OutlineButton
              type="submit"
              className="w-1/6 h-10 rounded-lg shadow-2xl opacity-100 hover:opacity-70"
              bgColor="rounded-lg cursor-pointer border-borderActive border-[1.5px] px-[16px] py-[10px] hover:bg-[#404b51]"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              wid={25}
              ht={25}
              isIcon={true}
              icon={Assets.Youtube}
              onClick={() => closeModal()}
              Loader={loader}
            />
            <OutlineButton
              type="submit"
              className="w-1/6 h-10 rounded-lg shadow-2xl opacity-100 hover:opacity-70"
              bgColor="rounded-lg cursor-pointer border-borderActive border-[1.5px] px-[16px] py-[10px] hover:bg-[#404b51]"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              wid={25}
              ht={25}
              isIcon={true}
              icon={Assets.Info}
              onClick={() => onClickExerciseInfo(exerciseId)}
              Loader={loader}
            />
            <OutlineButton
              type="submit"
              className="w-1/6 h-10  rounded-lg shadow-2xl px-[16px] py-[10px] opacity-100 hover:opacity-70 hover:bg-[#404b51]"
              bgColor="rounded-lg cursor-pointer border-borderActive border-[1.5px]"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              wid={25}
              ht={25}
              isIcon={true}
              icon={Assets.Chat}
              onClick={() =>
                onCommentsClick(exerciseId, workoutExerciseId, traineeId)
              }
              showNotify={hasLastComment}
            />
            <OutlineButton
              type="submit"
              className="w-1/6 h-10 rounded-lg shadow-2xl opacity-100 hover:opacity-70"
              bgColor="rounded-lg cursor-pointer border-borderActive border-[1.5px] px-[16px] py-[10px] hover:bg-[#404b51]"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              wid={25}
              ht={25}
              isIcon={true}
              icon={Assets.History}
              onClick={() =>
                onHistoryClick(
                  exerciseId,
                  workoutExerciseId,
                  traineeId,
                  exerciseName
                )
              }
            />
          </div>
          {removedSetIds.length === fields.length && isExercisePartial && (
            <label
              className={`px-2 text-xs text-red-400 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {t("AT_LEAST_ONE_SET")}
            </label>
          )}
          {isExercisePartial && (
            <PrimaryButton
              text={t("EXERCISE_DONE")}
              type="submit"
              disabled={removedSetIds.length === fields.length}
              isIcon={false}
              onClick={onExerciseCompleteClick}
              className="w-full h-[56px] rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              Loader={loading}
            />
          )}

          {isExerciseCompleted && (
            <PrimaryButton
              text={t("ReOpenExercise")}
              disabled={false}
              isIcon={false}
              onClick={onExerciseStartClick}
              className="w-full h-[56px] rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              Loader={loading}
            />
          )}
        </div>
      )}
      <ActionSheet
        workoutId={workoutId}
        workoutName={workoutName}
        options={[
          {
            id: 1,
            label: t("SWAP_EXERCISE"),
            image: Images.EditIcon,
            disabled: false,
            action: () => {
              let isAllPartial = getAllPartail(workoutId);
              onSwapClick(
                workoutExerciseId,
                workoutId,
                exerciseId,
                traineeId,
                exerciseName,
                isAllPartial
              );
            },
          },
          {
            id: 2,
            label: t("REMOVE_EXERCISE"),
            image: Images.CancelWorkout,
            disabled: false,
            action: () => {
              if (!isWorkoutHasOneExercise) {
                toggleExerciseMenu();
                onRemoveExercise(workoutExerciseId, workoutId);
              }
            },
          },
          {
            id: 2,
            type: "br",
          },
          {
            id: 3,
            label: t("ADD_ADVANCE_TECHNIQUE"),
            image: addAdvanceTechnique ? Images.Close : Images.AddSquare,
            action: toggleAdvanceTechnique,
          },
          {
            id: 4,
            label: t("ADD_INTENSITY_TRACKING"),
            image: addIntensityTracking ? Images.Close : Images.AddSquare,
            action: toggleIntensityTracking,
          },
          {
            id: 5,
            type: "br",
          },
          {
            id: 6,
            type: "custom",
            component: () => {
              return (
                <div className={`p-2 ${!isExercisePartial && "opacity-40"}`}>
                  <h1>{t("Technique Improvements")}</h1>
                  <div className="py-3 space-y-3">
                    <Checkbox
                      disabled={!isExercisePartial}
                      label={t("MOVEMENT_RANGE")}
                      checked={mobility_technique_value}
                      onChange={(e) =>
                        setValue(`mobility_technique`, e.target.checked)
                      }
                      direction={direction}
                    />
                    <Checkbox
                      disabled={!isExercisePartial}
                      label={t("MUSCLE_FEELING")}
                      checked={muscle_technique_value}
                      onChange={(e) =>
                        setValue(`muscle_technique`, e.target.checked)
                      }
                      direction={direction}
                    />
                    <Checkbox
                      disabled={!isExercisePartial}
                      label={t("CONTROLLED_MOVEMENT")}
                      checked={control_technique_value}
                      onChange={(e) =>
                        setValue(`control_technique`, e.target.checked)
                      }
                      direction={direction}
                    />
                  </div>
                </div>
              );
            },
          },
        ]}
        open={isExerciseMenuOpen}
        onClose={toggleExerciseMenu}
      />
    </div>
  );
};

export { Exercise };
