import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useDispatch, useSelector } from "react-redux";
import { getGraphInfo } from "store/programSlice/programActions";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { programActions } from "store/programSlice/programReducers";
import { Loader } from "components/compounds/Loader";
import { getTraineeDashboardStats } from "store/traineeSlice/traineeActions";

export default function Graph({ selectedExerciseForGraph, close , viewMode}) {
  const { t } = useTranslation();
  const { programGraphInfo, loader } = useSelector((state) => state.program);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { programId, active, status, traineeName } = Object.fromEntries(searchParams);
  const query = useParams();

  useEffect(() => {
    if (viewMode === "ADMIN" && query?.id && selectedExerciseForGraph && selectedExerciseForGraph.exercise_id) {
      dispatch(getGraphInfo(programId, selectedExerciseForGraph.exercise_id));
    }
    if(viewMode === "TRAINEE" && query?.id && selectedExerciseForGraph && selectedExerciseForGraph.exercise_id){
      dispatch(getGraphInfo(programId, selectedExerciseForGraph.exercise_id));
    }
  }, [query]);

  const onClose = () => {
    dispatch(programActions.resetGraphData());
    close();
  };

  // For Trainee Mode

  const { customerDetail } = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [dashboardData, setDashboardData] = useState({
    stats: {
      workoutCompleted: 0,
      achievements: 0,
      bestRecord: null,
      workoutInARow: 0,
    },
    futureWorkouts: [],
    completedWorkouts: [],
  })

  const fetchData = async () => {
    if (customerDetail?.User?.Trainee?.id && viewMode === "TRAINEE") {
      try {
        setLoading(true)
        const data = await getTraineeDashboardStats(customerDetail?.User?.Trainee?.id)

        setDashboardData(data)
        setLoading(false)
      } catch (error) {
        console.log('error', error)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [customerDetail?.User?.Trainee?.id])

  return (
    <>
      {selectedExerciseForGraph && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center"
            style={{ zIndex: 3000 }}
            onClick={onClose} // Close modal when clicking on the background
          >
            {/* Modal content - Prevent closing when clicked inside the modal */}
            <div
              className="flex flex-col w-3/4 h-auto max-h-[80vh] justify-between px-0 py-4 items-center bg-secondaryBackground rounded-xl p-0 md:p-2"
              onClick={(e) => {
                e.stopPropagation();
              }} // Prevent click from propagating to the background
            >
              <div className="flex flex-col w-full h-full justify-between px-4 py-4 items-center gap-y-4">
                <div className="flex flex-col w-full rounded-xl p-0 items-center gap-2">
                  <h3 className="text-xl text-textLightGray font-medium left-0">{selectedExerciseForGraph.exerciseName} </h3>
                  <div className="h-full w-full overflow-x-auto overflow-y-auto">
                   <div className="h-[65vh] w-full min-w-[600px] pt-10">
                   {loader ? <Loader size="w-8 h-8" fill="#003353" /> : <MyResponsiveLine data={programGraphInfo?.dateWeightMap ? programGraphInfo?.dateWeightMap : []} />}
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

// Utility to check if all data values are 0
const isAllZero = (data) => {
  return data.every(series => 
    series.data.every(point => point.y === 0)
  );
};

const MyResponsiveLine = ({ data /* see data tab */ }) => {
  const allZero = isAllZero(data);
  return (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 110, bottom: 60, left: 60 }} // Adjust margins as needed
    xScale={{ type: "point" }} // Ensure correct scale type for X-axis
    yScale={{
      type: "linear",
      min: 0, // Set minimum value to avoid overflow
      max: allZero ? 10 : 'auto',
    }}
    theme={{
      axis: {
        ticks: {
          line: {
            stroke: "#ffffff", // Optional: change tick line color to white (if visible)
          },
          text: {
            fill: "#ffffff", // Set the tick text color (X and Y axis labels) to white
          },
        },
        legend: {
          text: {
            fill: "#ffffff", // Axis label (X and Y axis titles) color to white
          },
        },
      },
    }}
    defs={[
      {
        id: "gradientA", // Unique ID for the gradient
        type: "linearGradient",
        colors: [
          { offset: 0, color: "#135C80" }, // Start color of the gradient
          { offset: 80, color: "#63B8DA" }, // End color of the gradient
        ],
      },
    ]}
    fill={[{ match: "*", id: "gradientA" }]}
    areaOpacity={0.2}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Date",
      legendOffset: 36,
      legendPosition: "middle",
      truncateTickAt: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Machine / Weight(kg)",
      legendOffset: -40,
      legendPosition: "middle",
      truncateTickAt: 0,
    }}
    curve="monotoneX"
    enableArea={true}
    colors={["#0e4469"]}
    gridXValues={[]}
    gridYValues={[]}
    enablePoints={true}
    enableGridX={false}
    enableGridY={true}
    isInteractive={true}
    tooltip={CustomTooltip}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabel="data.yFormatted"
    pointLabelYOffset={-12}
    enableTouchCrosshair={true}
    useMesh={true}
  />
)};

const CustomTooltip = ({ point }) => {
  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0, 0, 0, 0.7)",
        color: "white",
        borderRadius: "4px",
        fontSize: "14px",
        textAlign: "center",
      }}
    >
      Date:<strong>{point.data.date ? moment(point.data.date).format("DD/MM") : "-"}</strong>
      <br />
      {point.data.resistanceName === "weight" ? "Weight" : "Machine"}: <strong>{point.data.y} {point.data.resistanceName === "weight" ? "kg" : ""}</strong>
      <br />
      Repetition: <strong>{point.data.repsAtMaxWeight}</strong>
    </div>
  );
};
