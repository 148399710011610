import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  weeklyWorkoutLoader: false,
  programFilters: [],
  avaiableHours: [],
  programStatistics: [],
  programInfo: {},
  programGraphInfo: {},
  programStatisticsFilters: {},
  exercisesOfMuscle: [],
  selectedExerciseDetail: {},
  selectedExerciseHistory: {},
  programWeeklyStatistics: [],
  selectedExerciseDetailWeeklyView: {},
  selectedExerciseComments: [],
}
export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setProgramFilters: (state, action) => {
      state.programFilters = action.payload
    },
    setAvailableHourse: (state, action) => {
      state.avaiableHours = action.payload
    },
    setProgramInfo: (state, action) => {
      state.programInfo = action.payload
    },
    setProgramGraphInfo: (state, action) => {
      state.programGraphInfo = action.payload
    },
    setProgramStatisticsInfo: (state, action) => {
      state.programStatisticsFilters = action.payload
    },
    setProgramStatistics: (state, action) => {
      state.programStatistics = action.payload
    },
    setExercisesOfMuscle: (state, action) => {
      state.exercisesOfMuscle = action.payload
    },
    setSelectedExerciseDetail: (state, action) => {
      state.selectedExerciseDetail = action.payload
    },
    setSelectedExerciseHistory: (state, action) => {
      state.selectedExerciseHistory = action.payload
    },
    setProgramWeeklyStatistics: (state, action) => {
      state.programWeeklyStatistics = action.payload
    },

    setweeklyWorkoutLoader: (state, action) => {
      state.weeklyWorkoutLoader = action.payload
    },
    setSelectedExerciseDetailWeeklyView: (state, action) => {
      state.selectedExerciseDetailWeeklyView = action.payload
    },
    setSelectedExerciseComments: (state, action) => {
      state.selectedExerciseComments = action.payload
    },

    resetProgram: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        programFilters: [],
        avaiableHours: [],
      }
    },
    resetGraphData: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        programGraphInfo: {}
      }
    },
  },
})

export const useProgramSelector = () => useSelector((state) => state.programReducer)

export const programActions = programSlice.actions
export const programReducer = programSlice.reducer
