import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import { useTranslation } from 'react-i18next'

import { programActions } from './programReducers'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'
import { traineeActions } from 'store/traineeSlice/traineeReducer'
export const getProgramFilter = (id) => async (dispatch) => {
  try {
    axiosInstance.get(`programFilterByQty/${id}`).then((res) => {
      dispatch(programActions.setProgramFilters(res.result.result))
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const getProgramExercise =
  (id, traineeId, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(gymAdminActions.setExerciseLoader(true))
      await axiosInstance.get(`/getProgramExercise`, { params: { query: filter, gymId: id, traineeId: traineeId } }).then((response) => {
        let data = response?.result?.data.map((val) => ({ ...val, workout: [] }))
        dispatch(gymAdminActions.setExerciseLoader(false))
        dispatch(gymAdminActions.setExerciseInfo(data))
      })
    } catch (e) {
      dispatch(gymAdminActions.setExerciseLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }

export const setProgramAsDraft = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(programActions.setLoader(true))
    await axiosInstance.post(`/draftProgram`, data).then((response) => {
      dispatch(programActions.setLoader(false))
      action && action()
      CustomToastify(t('DRAFT_SAVED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}
export const getCoachAvailability = (id, selectedDate, duration, t, direction) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/coachAvailableHours/${id}`, { params: { startDate: selectedDate, duration: duration } })

    return response?.result?.finalData
  } catch (e) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const setProgram = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(programActions.setLoader(true))
    await axiosInstance.post(`/createProgram`, data).then((response) => {
      dispatch(programActions.setLoader(false))
      action && action()
      CustomToastify(t('PROGRAM_SET'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}

export const getProgramById = (id, state, t, direction, setModifiedState, setProgramName) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`getPrograms/${id}`)
      .then((response) => {
        setProgramName(response.result.programName)
        state(response.result.workoutList)
        setModifiedState(response.result.modifiyData)
      })
      .finally(() => {})
  } catch (e) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const editProgram = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(programActions.setLoader(true))
    await axiosInstance.put(`/editProgram`, data).then((response) => {
      dispatch(programActions.setLoader(false))
      action && action()
      CustomToastify(t('PROGRAM_SET'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}

export const getProgramInfo = (id, traineeId, t, direction) => async (dispatch) => {
  try {
    dispatch(programActions.setLoader(true))
    await axiosInstance
      .get(`getProgramInfo/${id}`, { params: { traineeId: traineeId } })
      .then((response) => {
        dispatch(programActions.setProgramInfo(response?.result))
      })
      .finally(() => {
        dispatch(programActions.setLoader(false))
      })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getGraphInfo = (id, selectedExerciseForGraph, t, direction) => async (dispatch) => {
  try {
    dispatch(programActions.setLoader(true))
    await axiosInstance
      .get(`getGraphForProgram/${id}`, { params: { exerciseId: selectedExerciseForGraph } })
      .then((response) => {
        dispatch(programActions.setProgramGraphInfo(response?.result))
      })
      .finally(() => {
        dispatch(programActions.setLoader(false))
      })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    // CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getProgramStatisticsFilters = (id, traineeId, t, direction) => async (dispatch) => {
  try {
    await dispatch(programActions.setProgramStatisticsInfo({}))
    await axiosInstance
      .get(`getProgramStatisticsFilters/${id}`, { params: { traineeId: traineeId } })
      .then((response) => {
        dispatch(programActions.setProgramStatisticsInfo(response?.result))
      })
      .finally(() => {})
  } catch (e) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramStatistics = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(programActions.setProgramStatistics([]))
    dispatch(programActions.setLoader(true))
    await axiosInstance
      .get(`getProgramStatistic/${data.programId}`, {
        params: { traineeId: data.traineeId, selectedWeek: data?.currentWeek, patternIds: data.patternIds, allWeeks: data?.totalWeeks },
      })
      .then((response) => {
        dispatch(programActions.setProgramStatistics(response?.result?.programData))
      })
      .finally(() => {
        dispatch(programActions.setLoader(false))
      })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramMuscleExercises = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(programActions.setExercisesOfMuscle([]))
    dispatch(programActions.setLoader(true))
    await axiosInstance
      .get(`getProgramStatsticExerciseMuscles/${data.programId}`, {
        params: {
          traineeId: data.traineeId,
          selectedWeek: data?.currentWeek,
          patternIds: data.patternIds,
          allWeeks: data?.totalWeeks,
          muscleId: data?.muscleId,
        },
      })
      .then((response) => {
        dispatch(programActions.setExercisesOfMuscle(response?.result?.uniqueExercisesArray))
      })
      .finally(() => {
        dispatch(programActions.setLoader(false))
      })
  } catch (e) {
    dispatch(programActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramSelectedExerciseDetail = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(programActions.setSelectedExerciseDetail({}))

    await axiosInstance
      .get(`programStatsticExerciseHistory/${data.programId}`, {
        params: {
          traineeId: data.traineeId,
          selectedWeek: data?.currentWeek,
          patternIds: data.patternIds,
          allWeeks: data?.totalWeeks,
          muscleId: data?.muscleId,
          exerciseId: data.exerciseId,
        },
      })
      .then((response) => {
        dispatch(programActions.setSelectedExerciseDetail(response?.result?.muscleExerciseDto))
      })
      .finally(() => {
        action && action()
      })
  } catch (e) {
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramSelectedExerciseAllHistory = (data, t, direction, action) => async (dispatch) => {
  dispatch(programActions.setSelectedExerciseHistory({}))
  try {
    await axiosInstance
      .get(`getAllProgramStatsticBasedOnExerciseId/${data.programId}`, {
        params: {
          traineeId: data.traineeId,
          exerciseId: data.exerciseId,
        },
      })
      .then((response) => {
        dispatch(programActions.setSelectedExerciseHistory(response?.result?.data))
      })
      .finally(() => {
        action && action()
      })
  } catch (e) {
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramStatisticsWeeklyWorkouts = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(programActions.setProgramWeeklyStatistics([]))
    dispatch(programActions.setweeklyWorkoutLoader(true))
    await axiosInstance
      .get(`getProgramStatsticWeeklyView/${data.programId}`, {
        params: { traineeId: data.traineeId, selectedWeek: data?.currentWeek },
      })
      .then((response) => {
        dispatch(programActions.setProgramWeeklyStatistics(response?.result?.weeklyWorkoutsData))
      })
      .finally(() => {
        dispatch(programActions.setweeklyWorkoutLoader(false))
      })
  } catch (e) {
    dispatch(programActions.setweeklyWorkoutLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getProgramSelectedExerciseDetailWeeklyView = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(programActions.setSelectedExerciseDetailWeeklyView({}))

    await axiosInstance
      .get(`getProgramStatsticExerciseHistoryWeeklyView/${data.programId}`, {
        params: {
          traineeId: data.traineeId,
          selectedWeek: data?.currentWeek,
          exerciseId: data.exerciseId,
          workoutId: data?.workoutId,
        },
      })
      .then((response) => {
        dispatch(programActions.setSelectedExerciseDetailWeeklyView(response?.result))
      })
      .finally(() => {
        action && action()
      })
  } catch (e) {
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getCommentsSelectedExercise = (data, t, direction, action) => async (dispatch) => {
  dispatch(programActions.setSelectedExerciseComments({}))
  try {
    await axiosInstance
      .get(`getCommentsSelectedExercise/${data.programId}`, {
        params: {
          traineeId: data.traineeId,
          exerciseId: data.exerciseId,
        },
      })
      .then((response) => {
        dispatch(programActions.setSelectedExerciseComments(response?.result))
      })
      .finally(() => {
        action && action()
      })
  } catch (e) {
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
